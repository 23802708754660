import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { createCartProduct } from "../../../../shared/hooks/useFetchServiceDetails";

import API_URLS from "../../../../shared/utils/ApiUrls";

import store from "../store";
 
interface CartState {

  cartResponse: any;

  loading: boolean;

  error: string | null;

}
 
const initialState: CartState = {

  cartResponse: null,

  loading: false,

  error: null,

};
 
// Async thunk for creating a cart

export const createV2Cart = createAsyncThunk(

  "createProductCart/shopExp",

  async (listItems: any, { rejectWithValue }) => {


    try {


      const reduxStore = store.getState();
      const addressQualificationData  = reduxStore?.addressQualification?.data?.addressQualification?.entity
       
      const headers = reduxStore?.brightspeedToken?.headers;

      const addressId = addressQualificationData?.addressId;

      const siteId = addressQualificationData?.siteId;

      const wireCenter = addressQualificationData?.wireCenter;

      const transactionId = addressQualificationData?.partnerSessionId;

      const channel = process.env.REACT_APP_CHANNEL_ID;

      const cancelVoiceData = reduxStore?.getAccordionStateSlice?.cancelVoice;
 
      const cartProductPostData = {

        baseline: {

          transactionId,

          channel,

          wirecenter: wireCenter,

          siteId,

          partnerOrderId: transactionId,

          intent: "",

        },

        currency: "USD",

        cartId: "",

        lineItems: listItems,

        ...(cancelVoiceData && { cartAddOnsAction: "remove" }),

        shippingAddress: {

          id: addressId,

          streetName: addressQualificationData?.address,

          additionalStreetInfo: "",

          postalCode: addressQualificationData?.postalCode,

          city: addressQualificationData?.city,

          state: addressQualificationData?.state,

          country: "US",

          wirecenter: wireCenter,

        },

        customerGroup: {

          key: "BS-Normal-User",

          typeId: "customer-group",

        },

      };
 
      const params = {

        "": [API_URLS?.createCart],

      };
      const response = await createCartProduct(params, cartProductPostData, headers);      
      const messageStatus = response?.data?.messageStatus?.find((item: any) => "statusCode" in item);
      if (messageStatus?.statusCode === "200") {
        localStorage.setItem('cartResponse',JSON.stringify(response?.data?.cartResponse));
        return response?.data?.cartResponse;

      } else {

        return rejectWithValue(response.data);

      }

    } catch (error) {

    //   return rejectWithValue(error.toString());

    }

  }

);
 
// Async thunk for updating a cart

export const updateV2Cart = createAsyncThunk(

  "updateProductCart/shopExp",

  async (listItems: any, { rejectWithValue }) => {

    try {

      const reduxStore = store.getState();
      const addressQualificationData  = reduxStore?.addressQualification?.data?.addressQualification?.entity

      const headers = reduxStore?.brightspeedToken?.headers;

      const { sessionId } = reduxStore?.user;

      const addressId = addressQualificationData?.addressId;

      const siteId = addressQualificationData?.siteId;

      const wireCenter = addressQualificationData?.wireCenter;

      const transactionId = siteId === 'bs' ? `brspd-${sessionId}` : siteId === 'ctl' ? `copper-${sessionId}` : `${siteId}-${sessionId}`;

      const channel = process.env.REACT_APP_CHANNEL_ID;

      const {cancelVoice :cancelVoiceData,voiceRemoved} = reduxStore?.getAccordionStateSlice;
      console.log(cancelVoiceData,"cancelVoice")
       const cartId  = reduxStore?.cartSlice?.cartResponse?.cartId;
      const cartProductPostData = {

        baseline: {

          transactionId,

          channel,

          wirecenter: wireCenter,

          siteId,

          partnerOrderId: transactionId,

          intent: "",

        },

        cartId: reduxStore?.cartSlice?.cartResponse?.cartId,

        actions: listItems,

        ...((cancelVoiceData) &&{ cartAddOnsAction: "remove" }),

        customerGroup: {

          key: "BS-Normal-User",

          typeId: "customer-group",

        },
        wirecenter: wireCenter,

      };
 
      const params = {

        "": [API_URLS?.updateCart],

      };
 
      const response = await createCartProduct(params, cartProductPostData, headers);

      const messageStatus = response?.data?.messageStatus?.find((item: any) => "statusCode" in item);
 
      if (messageStatus?.statusCode === "200") {
        localStorage.setItem('cartResponse',JSON.stringify(response?.data?.cartResponse));
        return response?.data?.cartResponse;
        
      } else {

        return rejectWithValue(response.data);

      }
  
    } catch (error) {

    //   return rejectWithValue(error.toString());

    }

  }

);
 
// Cart slice

const cartSlice = createSlice({

  name: "cart",

  initialState,

  reducers: {

    resetCartState: (state) => {

      state.cartResponse = null;

      state.loading = false;

      state.error = null;

    },

  },

  extraReducers: (builder) => {

    // createV2Cart

    builder.addCase(createV2Cart.pending, (state) => {

      state.loading = true;

      state.error = null;

    });

    builder.addCase(createV2Cart.fulfilled, (state, action: PayloadAction<any>) => {

      state.loading = false;

      state.cartResponse = action.payload;

    });

    builder.addCase(createV2Cart.rejected, (state, action) => {

      state.loading = false;

      state.error = action.payload as string;

    });

    // updateV2Cart

    builder.addCase(updateV2Cart.pending, (state) => {

      state.loading = true;

      state.error = null;

    });

    builder.addCase(updateV2Cart.fulfilled, (state, action: PayloadAction<any>) => {

      state.loading = false;

      state.cartResponse = action.payload;

    });

    builder.addCase(updateV2Cart.rejected, (state, action) => {

      state.loading = false;

      state.error = action.payload as string;

    });

  },

});
 
export const { resetCartState } = cartSlice.actions;

export default cartSlice.reducer;

 